import i18n from 'i18next';
import translations from '../data/translations';

i18n.init({
  fallbackLng: 'nb',
  defaultLanguage: 'nb',
  lng: "nb",
  initImmediate: false,
  resources: translations,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;