// gatsby-ssr.js
import React from 'react';
import i18n from './src/components/i18next';
import { I18nextProvider } from 'react-i18next';
import { PageContextProvider } from './src/components/pagecontextprovider';
import { ApolloProvider } from 'react-apollo'
import { client } from './src/apollo/client'

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}><ApolloProvider client={client}>{element}</ApolloProvider></I18nextProvider>;
};




/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
    return <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>;
  };
